import React from "react";
import { Link } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";


function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div>
      <div className="m-auto text-center px-4 pb-6 sm:flex sm:items-center sm:justify-center">

{/*<div className="fixed inset-0 transition-opacity">
  <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
</div>*/}

<div className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full sm:p-6">
  <div>
    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
    <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
    </svg>
    </div>
    <div className="mt-3 text-center sm:mt-5">
      <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
        404: We didn't find the page that you were looking for
      </h3>
      <div className="mt-2">
        <p className="text-sm leading-5 text-gray-500">
          Why not have an explore?
        </p>
      </div>
    </div>
  </div>
  <div className="mt-5 sm:mt-6">
    <span className="flex w-full rounded-md shadow-sm">


      <button type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
        <Link to="/">Back to Homepage</Link>
      </button>

    </span>
  </div>
</div>
</div>


      </div>
    </Layout>
  );
}

export default NotFoundPage;
